import React, { useState } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';

import ExcelIcon from '../icons/ExcelIcon';
import DividerFullWidth from '../parts/DividerFullWidth';
import FromToTime from '../parts/FromToTime';
import StatisticsSelect from '../inputs/StatisticsSelect';
import ProcessSummary from './ProcessSummary';
import TableSummaryNewLayout from './TableSummaryNewLayout';
import { useFilterContext } from '../../common/context/FilterContext';
import {colors as c} from '../../common/theme';
import {useAppContext} from '../../common/context/AppContext';
import Toggle from '../parts/Toggle';

type Props = {
    avg: boolean;
    handleSwitch: () => void;
};

const OutputStatistics = ({ avg, handleSwitch }: Props) => {
    const { global } = useAppContext();
    const { response, filters, getQuery, isNewLayout } = useFilterContext();
    const [selectedStatistics, setSelectedStatistics] = useState<'day0' | 'day2'>('day0');

    const download = () => {
        window.location.href = window.location.origin + '/api/filter/excel' + getQuery();
    };
    const isDay2 = selectedStatistics === 'day2';
    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding="13px 24px"
            >
                <Typography fontSize="20px" fontWeight={600}>
                    Output
                </Typography>
                <Button
                    disabled={global?.subscription === 'FREE' || global?.subscription === 'STARTER'}
                    onClick={download}
                    startIcon={<ExcelIcon />}
                    variant="outlined"
                    sx={{
                        textTransform: 'none',
                        fontWeight: 600,
                        color: c.gray.medium,
                        fontSize: '14px',
                        borderColor: c.primary,
                        whiteSpace: 'nowrap',
                        padding: '10px 20px',
                        ':hover': {
                            borderColor: c.gray.light,
                            backgroundColor: 'transparent',
                        },
                    }}
                >
                    Export Excel
                </Button>
            </Box>

            <DividerFullWidth />
            <Box padding="13px 24px" marginTop={isNewLayout ? '42px' : 0} maxWidth={311}>
                <FromToTime
                    label="Output timeframe"
                    dateTo={filters.outputTo.val}
                    setDateTo={filters.outputTo.set}
                    dateFrom={filters.outputFrom.val}
                    setDateFrom={filters.outputFrom.set}
                    tooltip="select the timeframe you want your filtered data to be analyzed in"
                />
            </Box>
            {isNewLayout && (
                <Paper>
                    <DividerFullWidth />
                    <Box display="flex" alignItems="center" padding="14px 24px">
                        <StatisticsSelect
                            value={selectedStatistics}
                            onChange={setSelectedStatistics}
                            disabled={
                                response.result &&
                                (response.result.tickersCount === 0 || response.isLoading)
                            }
                        />
                        <ProcessSummary priceData={response.result}/>
                        <Toggle
                            checked={avg}
                            handleSwitch={handleSwitch}
                            leftOption="Median"
                            rightOption="Average"
                        />
                    </Box>
                    <DividerFullWidth />
                    <TableSummaryNewLayout
                        showAvg={avg}
                        hideSearchInfo
                        priceData={isDay2 ? response.result.nextDaySummary : response.result}
                    />
                </Paper>
            )}
        </>
    );
};

export default OutputStatistics;
