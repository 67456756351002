import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import {Box, CardHeader, Grid, List, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import {loadStripe} from '@stripe/stripe-js';

import apiService from '../../common/utils/apiService';
import ApiError from '../parts/ApiError';
import {getCookie} from '../../common/utils/utils';
import {colors} from '../../common/theme';
import StripeIcon from '../icons/StripeIcon';

const stripePromise = loadStripe('pk_live_51KPBwoClFcbLOrxkhBfocDb1CXHj6ihdBoeb0rlkke316DO2uFkqCaT3xksSs8dZk0OZyuF3p8toivvbnftZmsBF00I7ulIewm');

interface Subscription {
    key: string;
    name: string;
    price: number;
}
interface Props {
    title: string;
    subtitle: string;
    price: number;
    items: string[];
    subscribe: (s: string) => void;
    stripe?: boolean;
    upgrade?: boolean;
}

const noExcelExportItem = 'No excel export';

const Display = ({title, subtitle, price, items, subscribe, stripe, upgrade}: Props) => {
    return (<Card sx={{height: '100%'}} raised>
        <CardHeader
            sx={(theme) => ({
                backgroundColor: colors.bg.light,
                paddingTop: theme.spacing(3),
                paddingBottom: theme.spacing(1),
                '.MuiCardHeader-title': {
                    fontWeight: 600,
                    paddingBottom: theme.spacing(1)
                },
                '.MuiCardHeader-subheader': {
                    color: colors.lightBlue
                },
            })}
            title={title.charAt(0) + title.substring(1).toLowerCase()}
            subheader="Subscription plan"
            titleTypographyProps={{ align: 'center' }}
            subheaderTypographyProps={{ align: 'center' }}
        />
        <CardContent style={{
            textAlign: 'center',
            paddingTop: 0
        }}>
            <Box display="flex" alignItems="baseline" justifyContent="center">
                <Typography variant="h1" component="h1" color="primary" sx={{fontWeight: 600, fontSize: '1.5rem'}}>${price}/</Typography>
                <Typography variant="subtitle1" color="primary" sx={{fontWeight: 600}}>{title === 'YEARLY' ? 'year' : 'mo'}</Typography>
            </Box>
            <List sx={(theme) => ({paddingTop: theme.spacing(2)})}>
                {
                    items.map((item, i) => {
                        if (item === 'EMPTY') {
                            return <li key={i} style={{height: '32px'}}></li>
                        }
                        let icon = <CheckIcon color="primary" />;
                        let itemColor = colors.lightBlue;
                        if (item === noExcelExportItem) {
                            icon = <CloseIcon sx={{color: colors.red}} />;
                            itemColor = colors.red;
                        }
                        return (
                            <ListItem key={i} sx={{paddingTop: 0, paddingBottom: 0}}>
                                <ListItemIcon sx={{minWidth: 32}}>{icon}</ListItemIcon>
                                <ListItemText sx={{color: itemColor, whiteSpace: 'nowrap'}} primary={item} />
                            </ListItem>
                        )
                    })
                }
            </List>
        </CardContent>
        <CardActions>
            <Box style={{width: '100%'}} display="flex" alignItems="center" justifyContent="center">
                <Button color="primary" variant="contained" role="link" sx={{textTransform: 'none', fontWeight: 600}} onClick={() => subscribe(title)}>
                    {upgrade ? 'Upgrade' : 'Subscribe now'}
                </Button>
            </Box>
        </CardActions>
        <CardContent style={{textAlign: 'center'}}>
            {stripe
                ? <>
                    <Typography variant="body2" component="p" sx={{color: colors.lightBlue}}>
                        *Payments managed by:
                    </Typography>
                    <a href="https://stripe.com/" target="_blank" rel="noreferrer"><StripeIcon /></a>
                </>
                : null}
        </CardContent>
    </Card>);
}

interface ProductDisplayProps {
    upgrade?: boolean;
}

export default function ProductDisplay({upgrade}: ProductDisplayProps) {
    const response = apiService<Subscription[]>('stripe/subscriptions', 0);

    if (response.error) {
        return <ApiError error={response.error}/>;
    }

    if (response.isLoading) {
        return <LinearProgress />;
    }

    const handleClick = async (key: string) => {
        const stripe = await stripePromise;

        const token = getCookie('XSRF-TOKEN');
        const params: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...(token ? { 'X-XSRF-TOKEN': token } : {}),
            }
        };
        const response = await fetch('/api/stripe/create-checkout-session?key=' + key, params);

        const session = await response.json();

        if (session.started) {
            window.location.href = '/';
        }

        // When the customer clicks on the button, redirect them to Checkout.
        const result = stripe ?  await stripe.redirectToCheckout({
            sessionId: session.id,
        }) : null;

        if (result && result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        }
    };
    function items(key: string): string[] {
        if (key === 'FREE_TRIAL' || key === 'FREE') {
            return [
                '1 month of historical data',
                '2 days full access',
                noExcelExportItem,
                'EMPTY',
                'EMPTY'
            ];
        } else if (key === 'STARTER') {
            return [
                '7 years of historical data',
                'Unlimited inputs',
                'Unlimited searches',
                'Custom filtering options',
                noExcelExportItem,
            ];
        } else if (key === 'MONTHLY' || key === 'YEARLY') {
            return [
                '7 years of historical data',
                'Unlimited inputs',
                'Unlimited searches',
                'Custom filtering options',
                'Excel export',
            ];
        }
        return [];
    }
    return <Grid container spacing={3}>
        {response.result.map((s, i) => {
            if (upgrade && s.key === 'FREE') return null;
            return (<Grid key={i} item xs={response.result.length === 4 ? 3 : 4}>
                <Display
                upgrade={upgrade}
                title={s.key}
                key={s.key}
                subtitle={s.name}
                price={s.price}
                items={items(s.key)}
                stripe={s.key === 'MONTHLY' || s.key === 'YEARLY'}
                subscribe={handleClick} />
            </Grid>)
        })}
    </Grid>;
}
